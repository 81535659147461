import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { useWindowDimensions } from "../../hooks/WindowDimension";
import CardAboutus from "../../components/card/card-aboutus";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Distribution from "../../components/Distribution";
import PersonnelNew from "../../components/PersonnelNew";
import SectionRocket from "../../components/SectionRocket";
import SectionVideos from "../../components/SectionVideos";
import Partners from "../../components/Partners";
import Roadmap from "../../components/Roadmap";
import RoadmapCarousel from "../../components/carousel/carousel-roadmap";
import SectionContribution from "../../components/SectionContribution";
import SectionEcosystem from "../../components/SectionEcosystem";
import WhoUseCarousel from "../../components/carousel/carousel-whouse";
import VoteCarousel from "../../components/carousel/carousel-vote";

function Index(props) {
    const [snsHover, setSnsHover] = useState([false, false, false, false, false, false, false, false]);
    const { height, width } = useWindowDimensions();
    const [widthState, setWidthState] = useState(width);
    const [prevWidth, setPrevWidth] = useState(width);
    const [loaded, setLoaded] = useState(false);
    const [loadedEarth, setLoadedEarth] = useState(false);

    useEffect(() => {
        if (isBrowser && width >= 800) setLoaded(true);
        else if (width < 800) setLoaded(true);
        if (prevWidth >= 800 && width < 800) {
            window.location.reload();
        } else if (prevWidth < 800 && width >= 800) {
            window.location.reload();
        }
        setPrevWidth(width);
    }, [width]);

    const handleOver = (i) => {
        let tempList = [...snsHover];
        tempList[i] = true;
        setSnsHover(tempList);
    };

    const handleLeave = (i) => {
        let tempList = [...snsHover];
        tempList[i] = false;
        setSnsHover(tempList);
    };

    const content3 = [
        {
            image: "/asset-new-pc/aboutus/1.svg",
            title: "Multi-smartcontract",
            description: "Infinite expansion of NFT mainet on one platform",
        },
        {
            image: "/asset-new-pc/aboutus/2.svg",
            title: "Standardless",
            description:
                "Beyond the NFT general-purpose standard, the nth transaction of all NFTs through super-universal NFT transactions regardless of the standard itself",
        },
        {
            image: "/asset-new-pc/aboutus/3.svg",
            title: "Interlocking with Real",
            description: "O2O NFT platform connecting virtual and real through IRL(in real life) smart contract",
        },
        {
            image: "/asset-new-pc/aboutus/4.svg",
            title: "Meta-Bridge Economy",
            description: "S2EXT2E, Decentralized economic system considering mutual contribution",
        },
        {
            image: "/asset-new-pc/aboutus/5.svg",
            title: "NFT Trading Mainnet matching Technology",
            description: "Support all NFT content transactions around the world through NFT block Crawling and Register-less transaction function",
        },
    ];

    // Earth
    const content4 = [
        {
            name: "Vision",
            content:
                "A representative Web3.0 platform that provides innovative technology and the best service that positively inspires the metaverse and real society.",
            bullets: [],
        },
        {
            name: "Philosophy",
            content:
                "Innovate the environment where data ownership is monopolized by a few platforms, and aim for a system that can return ownership rights of their data to participants and create new value.",
            bullets: [],
        },
        {
            name: "Technology",
            content: "",
            bullets: [
                "Supports all nth transactions of NFTs between platforms regardless of mainnet and standard",
                "Smart contract API",
                "NFT ultra-universal standardization technology",
                "NFT Launchpad Service",
                "In-web advertising service",
                "Direct NFT trading on the blockchain",
                "Program for issuing, managing and modifying NFT collections",
            ],
        },
        {
            name: "Service",
            content: "",
            bullets: [
                "Cross-platform interworking NFT n-th transaction technology",
                "Multi smart contract",
                "Standardless technology",
                "NFT trading technology with real objects",
                "UX/UI implementation optimized for NFT management",
                "launchpad",
            ],
        },
    ];

    // News
    const content5 = [
        {
            title: "롯데홈쇼핑, 에그버스와 'NFT 콘텐츠 사업 활성화' 업무협약 ",
            link: "https://www.etnews.com/20221111000161",
            date: "2022년 11월 11일",
        },
        {
            title: "롯데홈쇼핑·에그버스, NFT 콘텐츠 활성 위한 협약",
            link: "https://news.mt.co.kr/mtview.php?no=2022111516351249338",
            date: "2022년 10월 27일",
        },
        {
            title: "다날엔터, 에그버스에 전략 투자 유치… NFT 출시 예정",
            link: "https://www.coindeskkorea.com/news/articleView.html?idxno=81530",
            date: "2022년 9월 28일",
        },
        {
            title: "에그버스, 유니와이드와 맞손…NFT 융합 서비스 개발 ",
            link: "https://mobile.newsis.com/view.html?ar_id=NISX20221027_0002063952",
            date: "2022년 9월 28일",
        },
    ];

    // SNS
    const content6 = [
        {
            image: "/asset-new-pc/aboutus/sns/discord",
            link: "https://discord.gg/CbW42AxnsR",
        },
        {
            image: "/asset-new-pc/aboutus/sns/Twitter-Logo-back-b-90",
            link: "https://twitter.com/eggverse_io",
        },
        {
            image: "/asset-new-pc/aboutus/sns/telegram",
            link: "https://t.me/eggverse",
        },
        {
            image: "/asset-new-pc/aboutus/sns/opensea",
            link: "https://opensea.io/collection/egglord-dimensional-gate-engineer-guild",
        },
        {
            image: "/asset-new-pc/aboutus/sns/blog",
            link: "https://blog.naver.com/eggverse-",
        },
        {
            image: "/asset-new-pc/aboutus/sns/medium",
            link: "https://medium.com/@EGGVERSE.IO",
        },
        {
            image: "/asset-new-pc/aboutus/sns/github",
            link: "https://github.com/EGGVERSE",
        },
        {
            image: "/asset-new-pc/aboutus/sns/instagram",
            link: "https://www.instagram.com/eggverse.io",
        },
    ];

    const bgRef = useRef();
    const imgRef = useRef();
    const [showBg, setShowBg] = useState(false);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (showBg) {
                setShowContent(true);
            }
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, [showBg]);

    const roadmap = [
        {
            year: "2020",
            image: "/assets/roadmap/2020.svg",
            contents: [
                "Forming EGGVERSE project team",
            ]
        },
        {
            year: "2021",
            image: "/assets/roadmap/2021.svg",
            contents: [
                "EGGT Governance Token Ecosystem Planning",
                "EGGVERSE BM Planning",
                "EGGVERSE platform service planning",
                "Establish an EGGVERSE strategy",
                "NFT Marketplace",
                "Join development and management team",
                "Established EGGT Singapore Foundation",
                "Building a global team",
                "EGGVERSE beta version launch",
            ]
        },
        {
            year: "2022",
            image: "/assets/roadmap/2022.svg",
            contents: [
                "EGGVERSE released",
                "Multichain Support",
                "ERC721 KIP17 support",
                "Support for early NFT standards such as ‘Crypto Punk’",
                "EGGVERSE automatic NFT transaction registration service using blockchain (beta version)",
                "EGGVERSE community launched",
                "Launched NFT Launchpad service",
                "Signed a strategic business agreement with Lotte Home Shopping",
            ]
        },
        {
            year: "2023",
            image: "/assets/roadmap/2023.svg",
            contents: [
                "EGGT alpha version development",
                "EGGVERSE GOVERNANCE ROLE NFT DISTRIBUTION",
                "EGGVERSE LAUNCHPAD ADVANCED MODE DISTRIBUTION",
                "Support mainnet expansion",
                "EGGVERSE simple login distribution",
                "Multi-smart contract deployment",
            ]
        },
        {
            year: "2024",
            image: "/assets/roadmap/2024.svg",
            contents: [
                "EGGT ecosystem expansion (P2E Game NFT)",
                "EGGVERSE platform support mainnet added (BNB chain)",
                "Expansion of EGGT&EGGVERSE ecosystem (entry into Southeast Asian market)",
                "EGGVERSE platform support mainnet added (SOLANA chain)",
            ]
        },
        {
            year: "2025",
            image: "/assets/roadmap/2024.svg",
            contents: [
                "EGGVERSE Easy Login Distribution",
                "Deployment of SNS and Trade contribution application function",
                "Deployment of EGGT multi-voting feature",
                "Development and application of EGGVERSE multi-smart contract",
                "Development and application of EGGVERSE launchpad advanced mode",
                "NFT-compatible re-transaction API commercialization, Web3.0 token economy implementation",
                "EGGTFi development",
                "EGGTad distribution",
            ]
        }
    ]

    const whouse = [
        "/assets/whouse/1.svg",
        "/assets/whouse/2.svg",
        "/assets/whouse/3.svg",
        "/assets/whouse/4.svg",
        "/assets/whouse/5.svg",
    ];

    return (
        <div className={"font-Pretendard"}>
            <Header />
            <div className={"mx-auto flex min-h-screen flex-col text-white bg-back text-left"}>
                <div className={`absolute z-10 w-full duration-1000 ${showBg ? "opacity-100" : "opacity-0"}`} ref={bgRef}>
                    {loaded && (
                        <div>
                            {width > 800 ? (
                                <video className={"mx-auto w-full object-cover"} onLoadedData={() => setShowBg(true)} autoPlay muted playsInline loop>
                                    <source src={"/asset-new-pc/aboutus/eggt_banner.webm"} type={"video/webm"} />
                                    <source src={"/asset-new-pc/aboutus/eggt_banner.mp4"} type={"video/mp4"} />
                                </video>
                            ) : (
                                <video className={"mx-auto w-full object-cover"} onLoadedData={() => setShowBg(true)} autoPlay muted playsInline loop>
                                    <source src={"/asset-new-pc/aboutus/eggt_banner_mobile.webm"} type={"video/webm"} />
                                    <source src={"/asset-new-pc/aboutus/eggt_banner_mobile.mp4"} type={"video/mp4"} />
                                </video>
                            )}
                        </div>
                    )}
                    {/*<div className={"w-full mx-auto -translate-y-[100px] xlr:-translate-y-[60px]"}>*/}
                    {/*    <img className={"w-[80px] xlr:w-[40px] mx-auto"} src={"/asset-new-pc/aboutus/arrow-down-2.svg"} />*/}
                    {/*</div>*/}
                </div>

                {showBg && (
                    <div className={"flex flex-col w-full"}>
                        {/* Section 1 */}
                        <div
                            className={`relative z-20 flex w-full max-w-[1410px] mx-auto flex-col px-[10px] text-center duration-1000 lgr:px-[10px] ${
                                showContent ? "opacity-100" : "opacity-0"
                            }`}
                            style={{ paddingTop: bgRef.current?.clientHeight / (width > 1410 ? 5 : 6), height: bgRef.current?.clientHeight, paddingLeft: width > 1410 ? 0 : 20 }}
                        >
                            <div className={"text-left font-medium 2xl:text-[60px] xl:text-[40px] lgm:text-[30px] text-[25px] lgr:text-center"}>EGG N PARTNERS</div>
                            <div className={"text-left 2xl:text-[28px] xl:text-[20px] lgm:text-[18px] text-[14px] font-medium mt-[60px] max-w-[70%] lgr:max-w-[320px] lgr:text-center lgr:mx-auto"}>“For the implementation of the governance aggregation ecosystem
                                A novel governance token with multiple voting and ballot functions and,
                                Using multi-smart contracts and standardless systems
                                NFT compatible re-trade NFT O2O metaverse web3.0 platform”</div>
                            <a className={"mr-auto lgr:mx-auto"} href={"https://www.eggverse.io/"} target="_blank" rel="noreferrer">
                                <button className={"mt-[120px]"}>
                                    <img src={"/asset-new-pc/aboutus/EGGVERSE.svg"} />
                                </button>
                            </a>
                        </div>

                        <div className={"relative flex flex-row gap-x-[160px] mb-[100px] mx-auto text-center -translate-y-[160px] z-10 lgr:hidden"}>
                            <div className={"flex flex-col justify-center items-center w-[480px] h-[400px] rounded-[20px] border-[1px] border-[#313131]/60 backdrop-blur-2xl"}>
                                <div className={"flex flex-row"}>
                                    <img className={"my-auto"} src={"/asset-new-pc/aboutus/vote.svg"} />
                                    <div className={"text-[24px] font-bold my-auto ml-[10px]"}>Multi-voting function</div>
                                </div>
                                <div className={"max-w-[350px] text-[16px] mt-[70px]"}>In order to cast voting rights on multiple governances simultaneously with existing governance tokens, each governance token must be owned, but the proposed multi-voting feature allows one token to vote for each and every governance.</div>
                            </div>
                            <div className={"flex flex-col justify-center items-center w-[480px] h-[400px] rounded-[20px] border-[1px] border-[#313131]/60 backdrop-blur-2xl"}>
                                <div className={"flex flex-row"}>
                                    <img className={"my-auto"} src={"/asset-new-pc/aboutus/ballot.svg"} />
                                    <div className={"text-[24px] font-bold my-auto ml-[10px]"}>Ballot function</div>
                                </div>
                                <div className={"max-w-[350px] text-[16px] mt-[70px]"}>In order to fundamentally block the leakage of personal information through the tracking of contract distribution history recorded on the crypto-wallet address, stored assets and NFT, etc., voting records are left on the blockchain while the participant's cryptocurrency wallet address not be made public.</div>
                            </div>
                        </div>

                        <div className={"lg:hidden"}>
                            <VoteCarousel />
                        </div>

                        {width > 800 ? (
                            <div className={"bg-box relative flex flex-row z-10 small:flex-col px-[10px]"}>
                                <div className={"flex flex-row max-w-[1410px] pt-[60px] pb-[100px] mx-auto"}>
                                    <div className={"w-full h-full flex flex-col z-10 small:ml-auto small:mr-auto small:max-h-[300px]"}>
                                        <div className={"mt-auto mb-auto small:ml-0"}>
                                            <h1 className={"text-left 2xl:text-[60px] xl:text-[50px] text-[40px] font-bold leading-tight mt-auto lgr:text-center small:mb-[10px]"}>EGGT's ecosystem</h1>

                                            <p className={"text-left 2xl:text-[20px] xl:text-[18px] text-[16px] lgr:text-center max-w-[550px] mt-[50px]"}>EGGT's ecosystem consists of an aggregation of governance ecosystems, and all governance, including existing governance and to-be-created governance, can be accepted as a member of the ecosystem through EGGT's multiple voting function without any additional method. In addition, the basic structure of the governance of the EGGT economy is a simple form of rewarding EGGT holders who participated in fees and voting for agenda registration </p>
                                        </div>
                                    </div>
                                    <div className={"flex w-full h-full z-10 small:ml-auto small:mr-auto small:mt-0 small:max-h-[700px]"}>
                                        <img className={"w-full h-full object-contain m-auto small:m-auto scale-[1.1] -translate-y-[50px]"} src={"/asset-new-pc/aboutus/ecosystem.png"} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={"bg-box relative flex flex-row z-10 small:flex-col px-[10px] lgr:px-[30px] pt-[30px]"}>
                                <div className={"flex flex-col max-w-[1410px] pt-[60px] pb-[60px] mx-auto"}>
                                    <div className={"flex w-full h-full z-10 small:ml-auto small:mr-auto small:mt-0 small:max-h-[700px]"}>
                                        <img className={"w-full h-full object-contain m-auto small:m-auto -translate-y-[50px]"} src={"/asset-new-pc/aboutus/ecosystem.png"} />
                                    </div>
                                    <div className={"w-full h-full flex flex-col z-10 small:ml-auto small:mr-auto small:max-h-[300px]"}>
                                        <div className={"mt-auto mb-auto small:ml-0 small:mt-[60px] small:mt-0"}>
                                            <h1 className={"text-left text-[60px] lgr:text-[20px] small:text-center font-bold leading-tight mt-auto small:mb-[10px]"}>EGGT's ecosystem</h1>

                                            <p className={"text-left text-[20px] lgr:text-[14px] small:text-center max-w-[550px] mt-[50px] mx-auto"}>EGGT's ecosystem consists of an aggregation of governance ecosystems, and all governance, including existing governance and to-be-created governance, can be accepted as a member of the ecosystem through EGGT's multiple voting function without any additional method. In addition, the basic structure of the governance of the EGGT economy is a simple form of rewarding EGGT holders who participated in fees and voting for agenda registration </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={`duration-1000 ${showContent ? "opacity-100" : "opacity-0"}`}>
                            {/* Section 2 */}
                            <div className={"mx-auto flex w-full max-w-[1410px] flex-col px-[10px] pt-[150px] text-center lgr:h-auto lgr:pt-0"}>
                                <div className={"mx-auto font-bold text-[30px] 2xl:text-[50px] lgr:text-[20px] lgr:mt-[60px]"}>We inspire society</div>
                                <div className={"mx-auto mt-[20px] max-w-[940px] text-[18px] 2xl:text-[30px] lgr:mt-[20px] lgr:text-[14px]"}>
                                    for the continued development and prosperity of mankind by using your talents and hard work as an artist
                                </div>
                            </div>

                            {/* Section 3 */}
                            <div className={"mx-auto flex w-full max-w-[1410px] flex-col px-[10px] pt-[250px] text-center lgr:pt-[100px] hidden pointer-events-none"}>
                                <div className={"mx-auto text-[30px] font-bold 2xl:text-[50px] lgr:text-[20px]"}>Thechnical Points of EGGVERSE</div>
                                <div className={"mx-auto mt-[10px] max-w-[940px] text-[18px] 2xl:text-[30px] lgr:mt-[20px] lgr:text-[14px]"}>Multi-chain O2O NFT Exchange</div>
                                <div className={"mx-auto mt-[60px] flex w-full max-w-[1200px] flex-col"}>
                                    <div className={"mx-auto grid grid-cols-3 gap-[40px] xlr:grid-cols-1"}>
                                        {content3.slice(0, 3).map((item, i) => (
                                            <div key={i}>
                                                <CardAboutus data={item} idx={i + 1} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className={"mx-auto mt-[40px] grid grid-cols-2 gap-[40px] xlr:grid-cols-1"}>
                                        {content3.slice(3, 5).map((item, i) => (
                                            <div key={i}>
                                                <CardAboutus data={item} idx={i + 4} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* videos */}
                            <div className={""}>
                                <SectionVideos />
                            </div>

                            {/* Section 4 */}
                            <div className={"relative mx-auto flex w-full flex-col overflow-y-hidden pt-[200px] text-center xlr:hidden"}>
                                <div className={"absolute w-full"}>
                                    <img className={"h-full w-full object-cover"} src={"/asset-new-pc/aboutus/earth.webp"} ref={imgRef} onLoad={() => setLoadedEarth(true)} />
                                </div>
                                {loadedEarth && (
                                    <div
                                        className={"2xl:mb-[100px] flex flex-col"}
                                        style={{
                                            minHeight: imgRef.current?.clientHeight,
                                            paddingTop: imgRef.current?.clientHeight / 8.0,
                                            paddingBottom: imgRef.current?.clientHeight / 2.8,
                                        }}
                                    >
                                        <div
                                            className={"relative mx-auto flex w-full flex-row px-[400px]"}
                                            style={{ paddingLeft: imgRef.current?.clientHeight / 2.7, paddingRight: imgRef.current?.clientHeight / 2.7 }}
                                        >
                                            <div className={"mr-auto"}>
                                                <ToggleMenu data={content4[0]} dir={"left"} />
                                            </div>
                                            <div className={"ml-auto"}>
                                                <ToggleMenu data={content4[1]} dir={"right"} />
                                            </div>
                                        </div>
                                        <div
                                            className={"relative mx-auto mt-auto flex w-full flex-row px-[400px]"}
                                            style={{ paddingLeft: imgRef.current?.clientHeight / 2.7, paddingRight: imgRef.current?.clientHeight / 2.7 }}
                                        >
                                            <div className={"mr-auto"}>
                                                <ToggleMenu data={content4[2]} dir={"left"} />
                                            </div>
                                            <div className={"ml-auto"}>
                                                <ToggleMenu data={content4[3]} dir={"right"} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Section 4 mobile */}
                            <div className={"mx-auto mt-[100px] flex max-w-[800px] flex-col xl:hidden px-[10px]"}>
                                <div className={"mx-auto text-center text-[20px] font-bold"}>About EGGVERSE</div>
                                <div className={"mt-[20px] flex flex-col"}>
                                    {content4.map((item, i) => (
                                        <div key={i}>
                                            <ToggleMenu data={item} dir={"mobile"} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Mutual Contribution  */}
                            <div>
                                <SectionContribution />
                            </div>

                            {/* Ecosystem  */}
                            <div className={"xlr:hidden"}>
                                <SectionEcosystem />
                            </div>
                            <div className={"xl:hidden py-[30px]"}>
                                <div className={"font-bold text-[20px] mx-auto text-center mb-[10px]"}>EGGVERSE Launchpad Who Use</div>
                                <WhoUseCarousel data={whouse} />
                            </div>

                            {/* rocket */}
                            <div className={"lgr:mt-[50px]"}>
                                <SectionRocket />
                            </div>

                            {/* Distribution */}
                            <div className={"mt-[50px] lgr:mt-[100px]"}>
                                <Distribution />
                            </div>

                            {/* Personnel */}
                            <div className={""}>
                                <PersonnelNew />
                            </div>

                            {/* Roadmap */}
                            <div className={"2xlr:hidden"}>
                                <Roadmap data={roadmap} />
                            </div>
                            <div className={"2xl:hidden flex flex-col mb-[100px]"}>
                                <div className={"relative flex flex-row text-white"}>
                                    <div className={"flex flex-col mx-auto"}>
                                        <h1 className={"text-center text-[50px] lgr:text-[30px] font-bold mt-[60px]"}>Roadmap</h1>
                                        <p className={"text-center text-[20px] lgr:text-[16px] mt-[10px] mb-[60px] text-[#888888] mb-[60px]"}>The path we have walked, the direction we aim for.</p>
                                    </div>
                                </div>
                                <RoadmapCarousel data={roadmap} />
                            </div>

                            {/* Partners */}
                            <Partners />

                            {/* Section 6 (SNS) */}
                            <div className={"relative mx-auto my-[300px] flex w-full max-w-[1410px] flex-col px-[10px] xlr:my-[100px]"}>
                                <div className={"mx-auto text-[30px] font-bold 2xl:text-[50px] lgr:text-[20px]"}>Join Community</div>
                                <div className={"mx-auto mt-[30px] flex flex-col gap-y-[40px] lgr:gap-y-[20px]"}>
                                    <div className={"flex flex-row gap-x-[40px] lgr:gap-x-[20px]"}>
                                        {content6.slice(0, 4).map((item, i) => (
                                            <div key={i} onMouseOver={() => handleOver(i)} onMouseLeave={() => handleLeave(i)}>
                                                <a href={item.link}>
                                                    <a>
                                                        <img className={"h-[90px] w-[90px] xlr:h-[60px] xlr:w-[60px]"} src={snsHover[i] ? `${item.image}-active.svg` : `${item.image}.svg`} />
                                                    </a>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={"flex flex-row gap-x-[40px] lgr:gap-x-[20px]"}>
                                        {content6.slice(4, 8).map((item, i) => (
                                            <div key={i} onMouseOver={() => handleOver(i + 4)} onMouseLeave={() => handleLeave(i + 4)}>
                                                <a href={item.link}>
                                                    <a>
                                                        <img className={"h-[90px] w-[90px] xlr:h-[60px] xlr:w-[60px]"} src={snsHover[i + 4] ? `${item.image}-active.svg` : `${item.image}.svg`} />
                                                    </a>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className={"mb-[100px]"}></div>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

function Person(props) {
    return (
        <div></div>
    );
}

function ToggleMenu(props) {
    const [show, setShow] = useState(true);
    const contentRef = useRef();

    const handleClick = () => {
        setShow(!show);
    };

    if (props.dir === "mobile") {
        return (
            <div className={"mb-[10px] w-full cursor-pointer rounded-[10px] bg-box p-[20px]"} onClick={() => setShow(!show)}>
                <div className={"flex h-full flex-row"}>
                    <div className={"flex flex-row"}>
                        <img className={"w-[15px] mr-[10px]"} src={`/assets/${props.data.name}.svg`} />
                        <div className={"my-auto mr-auto text-[16px] font-semibold"}>{props.data.name}</div>
                    </div>
                    <img className={`my-auto ml-auto w-[30px] duration-200 ${show && "rotate-180"}`} src={"/asset-new-pc/aboutus/arrow-down-white.svg"} />
                </div>
                <div className={`border-t-[1px] border-gray1 duration-200 ${show ? "mt-[20px] h-auto pt-[10px]" : "h-0 opacity-0 pointer-events-none"}`}>
                    {props.data.content}
                    {props.data.bullets.map((item, i) => (
                        <div className={"my-[5px] flex flex-row"} key={i}>
                            <div className={"mt-[8px] h-[5px] min-w-[5px] max-w-[5px] rounded-full bg-white"} />
                            <div className={"my-auto ml-[10px]"}>{item}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <div className={"relative flex flex-col"}>
                <div className={"flex h-[52px] w-[170px] cursor-pointer flex-col rounded-full bg-box px-[20px] py-[5px]"} onClick={handleClick}>
                    <div className={"my-auto flex flex-row"}>
                        <div className={"my-auto mr-auto text-[18px]"}>{props.data.name}</div>
                        <img className={`ml-auto duration-200 ${show && "rotate-180"}`} src={"/asset-new-pc/aboutus/arrow-down.svg"} />
                    </div>
                </div>
                <div
                    className={`absolute mt-[10px] mr-auto min-w-[330px] max-w-[330px] rounded-[10px] bg-box px-[25px] py-[15px] text-left text-[14px] duration-200 ${
                        show ? "opacity-100" : "pointer-events-none opacity-0"
                    }`}
                    ref={contentRef}
                    style={{ transform: `translateX(${props.dir === "left" ? 170 - 330 : 0}px) ${show ? "translateY(55px)" : "translateY(35px)"}` }}
                >
                    <div className={"flex flex-col"}>
                        {props.data.content}
                        <div>
                            {props.data.bullets.map((item, i) => (
                                <div className={"my-[10px] flex flex-row"} key={i}>
                                    <div className={"mt-[8px] h-[5px] min-w-[5px] max-w-[5px] rounded-full bg-white"} />
                                    <div className={"my-auto ml-[10px] text-[16px]"}>{item}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
