import React from "react";

export default function CardAboutus({ data, idx }) {
    return (
        <div className={"flex h-[330px] min-w-[360px] max-w-[360px] lgr:min-w-[330px] lgr:max-w-[330px] rounded-[10px] bg-gradient-to-b from-[#363638] to-[#232329]"}>
            <div className={"mx-auto mt-[60px]"}>
                <img className={"mx-auto h-[50px] w-[50px]"} src={`/asset-new-pc/aboutus/${idx}.svg`} />
                <div className={"mx-auto mt-[20px] text-center text-[20px]"}>{data.title}</div>
                <p className={"mt-[30px] px-[30px] text-center text-[14px] font-medium text-gray2"}>{data.description}</p>
            </div>
        </div>
    );
}