import React, {useEffect, useState} from "react";

export default function SectionContribution() {
    const data = [
        {
            title: "SNS to Earn",
            content: "Your SNS about EGGVERSE is treated as one of mutual contribution.",
            image: "/assets/contribution/phone.webp",
        },
        {
            title: "Trade 2 Earn",
            content: "Your Individual distribution is calculated in E engine.",
            image: "/assets/contribution/nft.webp",
        },
        {
            title1: "Individual distribution amount",
            title2: " is linear to the total volume of SNS and trade.",
            contents: [
                "R - Individual distribution amount",
                "r - Analyzer fee",
                "x - Transaction volume",
                "s - SNS volume",
            ],
            image: "/assets/contribution/formula2.webp",
        },
    ]

    return (
        <div className={"relative max-w-[1420px] py-[100px] flex flex-col z-10 mx-auto small:flex-col small:max-h-[1200px] px-[10px]"}>
            <div className={"text-center 2xl:text-[50px] text-[30px] lgr:text-[20px] font-bold leading-tight tracking-wide"}>Mutual contribution</div>
            <div className={"2xl:text-[30px] text-[18px] lgr:text-[14px] text-center max-w-[1000px] mx-auto mt-[30px] lgr:mt-[10px]"}>All activities such as SNS, listing NFT, trading, holding EGGT and etc., are calculated as mutual contribution. Benefits of utilities based on </div>
            <div className={"flex flex-row 2xlr:flex-col mt-[80px] lgr:mt-[30px]"}>
                <div className={"flex flex-col mr-auto 2xlr:mx-auto gap-y-[60px] 2xlr:gap-y-[20px]"}>
                    <div className={"flex flex-row w-[568px] 2xlr:w-[360px]"}>
                        <div className={"min-w-[328px] 2xlr:min-w-[206px]"}>
                            <img className={"w-full h-full object-cover"} src={data[0].image} />
                        </div>
                        <div className={"flex flex-col bg-box w-full p-[20px]"}>
                            <div className={"font-bold"}>{data[0].title}</div>
                            <div className={"mt-[30px] 2xlr:mt-[15px] 2xlr:text-[12px]"}>{data[0].content}</div>

                        </div>
                    </div>
                    <div className={"flex flex-row w-[568px] 2xlr:w-[360px]"}>
                        <div className={"min-w-[328px] 2xlr:min-w-[206px]"}>
                            <img className={"w-full h-full object-cover"} src={data[1].image} />
                        </div>
                        <div className={"flex flex-col bg-box w-full p-[20px]"}>
                            <div className={"font-bold"}>{data[1].title}</div>
                            <div className={"mt-[30px] 2xlr:mt-[15px] 2xlr:text-[12px]"}>{data[1].content}</div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col 2xlr:mt-[20px] 2xlr:mx-auto bg-box w-[760px] 2xlr:w-[360px] 2xlr:h-[270px] px-[40px] py-[60px] 2xlr:p-[20px] text-[20px] 2xlr:text-[16px]"}>
                    <div className={"flex flex-row"}>
                        <div className={"font-bold"}>{data[2].title1} {data[2].title2}</div>
                    </div>
                    <div className={"w-[670px] 2xlr:w-auto my-auto"}>
                        <img className={"w-full h-full object-cover"} src={data[2].image} />
                    </div>
                    <div className={"flex flex-col mt-auto 2xlr:text-[14px]"}>
                        {data[2].contents.map((item) => (
                            <div>{item}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}