import useWindowDimensions from "./utils/WindowDimensions";
import { useState } from "react";
import {Link, Navigate} from "react-router-dom";

export default function Header() {
    const { height, width } = useWindowDimensions();
    const [showMenu, setShowMenu] = useState(false);
    const [showLangMenu, setShowLangMenu] = useState(false);

    const handleClick = () => {
        setShowMenu(!showMenu);
    }

    return (
        <div className={`fixed top-0 z-50 flex h-[80px] w-full flex-row backdrop-blur-2xl px-[20px]`}>
            <div className={"mx-auto w-full flex flex-row max-w-[1410px]"}>
                <Link className={"my-auto"} to={"/"}>
                    {/*<img className={"my-auto "} src={"/assets/logo.svg"} onClick={() => window.location.reload()} />*/}
                    <div className={"text-white font-bold text-[20px] my-auto"} onClick={() => window.location.reload()}>Egg n Partners</div>
                </Link>
                <button
                    className={"ml-auto flex flex-col my-auto w-[140px] h-[42px] text-white font-semibold bg-[#6355FF] duration-200 hover:opacity-100"}
                    onClick={() => setShowLangMenu(!showLangMenu)}
                >
                    <div className={"flex flex-row mx-auto my-auto text-[14px]"}>
                        <div className={"my-auto mr-[10px]"}>Documents</div>
                        <img className={`w-[12px] my-auto duration-200 ${showLangMenu ? "rotate-180" : ""}`} src={"/assets/arrow-down.svg"} />
                    </div>
                    {showLangMenu ? (
                        <div className={"absolute translate-y-[50px] flex flex-col justify-center w-[140px] bg-[#202225] text-[14px]"}>
                            <a href={"https://eggverse.gitbook.io/eggt-documents-1/start/eggt-whitepaper_v.3.1"} target="_blank" rel="noreferrer">
                                <div className={"h-[42px] flex flex-col"}>
                                    <div className={"my-auto"}>English/영문</div>
                                </div>
                            </a>
                            <div className={"w-11/12 h-[1px] bg-[#33373B] mx-auto"} />
                            <a href={"https://eggverse.gitbook.io/kr-or-eggt-white-paper/start/eggt-whitepaper_v.3.1"} target="_blank" rel="noreferrer">
                                <div className={"h-[42px] flex flex-col"}>
                                    <div className={"my-auto"}>Korean/국문</div>
                                </div>
                            </a>
                        </div>
                    ) : (<div></div>)}
                </button>
            </div>
        </div>
    );
}