import {useEffect, useState} from "react";
import personnel from "../pages/aboutus/personnel.json";

export default function PersonnelNew() {
    const [department, setDepartment] = useState("C-level");

    const handleClick = (dep) => {
        setDepartment(dep);
    }

    return (
        <div className={"relative h-full w-screen pt-[100px] lgr:pt-[50px] bg-box text-white"}>
            <div className={"big:max-w-[1400px] big:ml-auto big:mr-auto"}>
                <h1 className={"text-center text-[50px] lgr:text-[30px] font-bold leading-tight tracking-wide"}>Members</h1>
                <p className={"text-center text-[20px] lgr:text-[16px] mt-[10px] mb-[30px] text-[#888888] leading-tight tracking-wide"}>Passionate, as always.</p>
                <div className={"max-w-[1200px] w-11/12 mx-auto flex flex row text-[38px] lgr:text-[20px] gap-x-[30px] text-gray5 px-[10px] xlr:justify-center mb-[30px] border-b-[2px] border-gray1 px-[30px]"}>
                    <button onClick={() => handleClick("C-level")}>
                        <div className={`${department === "C-level" && "text-white"}`}>C-LEVEL</div>
                    </button>
                    {/*<button onClick={() => handleClick("Team")}>*/}
                    {/*    <div className={`${department === "Team" && "text-white"}`}>TEAM</div>*/}
                    {/*</button>*/}
                    <button onClick={() => handleClick("Advisor")}>
                        <div className={`${department === "Advisor" && "text-white"}`}>ADVISOR</div>
                    </button>
                </div>
                <div>
                    <h2 className={"text-center text-[25px] text-[#888888] font-semibold mb-[50px]"}>{department}</h2>
                    <div className={"flex flex-col justify-center"}>
                        <div className={`grid ${department === "C-level" ? "big:grid-cols-2" : "big:grid-cols-3"} small:grid-cols-1 justify-center pb-[100px]`}>
                            {personnel.filter(person => person.department === department).map((person) => (
                                <Person data={person} department={department} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Person({ data, department }) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(false);
    }, [department]);

    return (
        <div className={"big:w-[300px] small:max-w-[300px] small:h-auto m-auto mt-0 mb-[30px] small:pl-[10px] small:pr-[10px] text-center"}>
            <img className={"w-[150px] m-auto rendering-crisp-edges rendering-auto"} src={`/assets/personnel/${data.image}`} />
            <p className={"text-[20px] mt-[10px] font-semibold"}>{data.name}</p>
            <p className={"text-[16px] text-[#888888] mb-[10px] font-semibold"}>{data.division}</p>
            <div className={"flex flex-row justify-center"}>
                <button
                    className={`w-[25px] h-[25px] mb-[10px] rounded-2xl rounded-[6px] duration-200 hover:scale-110 hover:shadow-md hover:bg-custom_blue-600 ${data.link ? "bg-point1" : "bg-gray-400 pointer-events-none"}`}
                    onClick={() => window.open(data.link)}
                >
                    <img className={"w-[15px] m-auto"} src={`/assets/icons/${data.linkImg ? data.linkImg : "link_white.png"}`} />
                </button>
                <button className={"w-[100px] h-[25px] bg-point1 rounded-[6px] ml-[10px] duration-200"} onClick={() => setShow(!show)}>
                    <div className={"flex flex-row pl-[10px] pr-[10px]"}>
                        <p className={"text-[15px] text-white"}>Profile</p>
                        <img className={`w-[15px] h-[15px] mt-auto mb-auto ml-auto duration-100 ${show === true ? "" : "rotate-180"}`} src={"./assets/icons/arrow_up.png"} />
                    </div>
                </button>
            </div>
            {show === true ? (
                <div className={"w-[280px] bg-[#464C52] text-white rounded-2xl p-[10px] duration-200 mx-auto"}>
                    {data.careers.map((career) => (
                        <p className={"text-[16px]"}>- {career}</p>
                    ))}
                </div>
            ) : (<div></div>)}
        </div>
    );
}