import React from "react";

export default function Roadmap({ data, idx }) {
    return (
        <div className={`min-w-[288px] max-w-[288px] h-[580px] flex flex-col p-[20px] ${data.year === "2025" ? "text-[14px]" : ""} ${idx % 2 === 0 ? "bg-box" : "bg-[#292C30]"}`}>
            <img className={"w-[27px] h-[27px]"} src={data?.image} />
            <div className={"mt-[20px] font-bold text-[40px]"}>{data.year}</div>
            <div className={"flex flex-col mt-[10px]"}>
                {data.contents.map((item, i) => (
                    <div className={"my-[5px] flex flex-row"} key={i}>
                        <div className={"mt-[8px] h-[5px] min-w-[5px] max-w-[5px] rounded-full bg-point1"} />
                        <div className={"my-auto ml-[10px]"}>{item}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}