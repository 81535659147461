import Header from "../components/Header";
import Footer from "../components/Footer";
import Section_1 from "../components/Section_1.js";
import SectionRocket from "../components/SectionRocket.js";
import Section_3 from "../components/Section_3.js";
import SNS from "../components/SNS.js";
import Distribution from "../components/Distribution.js";
import Roadmap from "../components/Roadmap.js";
import Partners from "../components/Partners.js";
import Personnel from "../components/Personnel";

function Home() {
    return (
        <div>
            <Header />
            <div></div>
            <Footer />
        </div>
    );
}

export default Home;