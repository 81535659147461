import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./pages/Router";
import {useState} from "react";
import Loading from "./components/documents/Loading";

function App() {
    const [login, setLogin] = useState(false);

    return (
        <div className="App overflow-x-hidden">
            <BrowserRouter>
                <Router login={login} setLogin={setLogin}/>
            </BrowserRouter>
        </div>
    );
}

export default App;
