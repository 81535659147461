import useWindowDimensions from "./utils/WindowDimensions";
import {Link} from "react-router-dom";

export default function SectionRocket() {
    const { height, width } = useWindowDimensions();

    if (width >= 1300) {
        return (
            <div className={"bg-box relative flex flex-row z-10 mx-auto small:flex-col px-[10px]"}>
                <div className={"flex flex-row max-w-[1410px] pt-[100px] pb-[120px] mx-auto"}>
                    <div className={"w-full h-full flex flex-col z-10 small:ml-auto small:mr-auto small:max-h-[300px]"}>
                        <div className={"mt-auto mb-auto small:ml-0 small:mt-[60px]"}>
                            <h1 className={"text-left text-[60px] font-bold leading-tight mt-auto small:text-center small:mb-[10px]"}>Support launchpad for</h1>
                            <h1 className={"text-left text-[60px] font-bold leading-tight mt-auto mb-[30px] small:text-center small:mb-[10px]"}>inspiration</h1>

                            <p className={"text-left text-[20px] small:text-center max-w-[550px]"}>The Easiest and Most Professional Tool for generation of NFT collection and Minting Event</p>
                            <p className={"text-left text-[20px] mt-[20px] small:text-center max-w-[550px]"}>EGGVERSE Launchpad is a web3.0 platform to create NFT minting event at the early stage of their projects when they have an idea, concept, or art yet haven't minted NFT</p>
                        </div>
                        <button className={""}>
                            <a href={"https://www.eggverse.io/launchpad"}>
                                <img className={"w-[200px]"} src={"/assets/button.svg"} />
                            </a>
                        </button>
                    </div>
                    <div className={"flex w-full h-full z-10 small:ml-auto small:mr-auto small:mt-0 small:max-h-[700px]"}>
                        <img className={"w-full h-full object-contain m-auto small:m-auto scale-[1.3]"} src={"/assets/rocket_pc.webp"} />
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className={"relative flex flex-col ml-auto mr-auto px-[15px] pt-[50px]"}>
                <div className={"flex flex-col bg-box p-[25px] rounded-[10px]"}>
                    <div className={"w-full h-full flex flex-col w-auto mr-auto text-left"}>
                        <div className={"w-full"}>
                            <h1 className={"text-left text-[24px] font-bold leading-tight mt-auto"}>Support launchpad for</h1>
                            <h1 className={"text-left text-[24px] font-bold leading-tight mt-auto mb-[30px]"}>inspiration</h1>
                            <p className={"text-left text-[14px]"}>The Easiest and Most Professional Tool for</p>
                            <p className={"text-left text-[14px]"}>Generation of NFT collection and Minting Event</p>
                        </div>
                        <button className={"mt-[40px]"}>
                            <a href={"https://www.eggverse.io/launchpad"}>
                                <img className={"w-[150px]"} src={"/assets/button.svg"} />
                            </a>
                        </button>
                    </div>
                    <div className={"flex w-full max-w-[400px] h-auto h-full ml-auto small:mb-[10px]"}>
                        <img className={"m-auto mr-[60px] object-contain small:m-auto translate-x-[30px] -translate-y-[30px] scale-[1.5]"} src={"/assets/rocket_mobile.webp"} />
                    </div>
                </div>
            </div>
        );
    }
}