import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardRoadmap from "../card/card-roadmap";

function VoteCarousel({ data }) {
    const [current, setCurrent] = useState(0);
    const sliderRef = useRef();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(current > data?.length - 2 ? 0 : current + 1);
            handleClick(current > data?.length - 2 ? 0 : current + 1);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [current, data?.length]);

    const handleClick = (i) => {
        if (current === data?.length - 1) {
            sliderRef.current?.slickGoTo(current + 1);
            setCurrent(i);
        } else {
            sliderRef.current?.slickGoTo(i);
            setCurrent(i);
        }
    };

    const settings = {
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        dots: true,
        variableWidth: false,
        afterChange: (current) => setCurrent(current),
        appendDots: (dots) => {
            return (
                <div className="flex justify-center gap-3 px-4 pt-4">
                    <div className={"flex justify-center"}>
                        {data?.map((_, i) => (
                            <button
                                className="mr-[6px] -translate-y-[15px] rounded-3xl bg-gray2"
                                style={{
                                    width: i === current ? 50 : 4,
                                    opacity: i === current ? 1 : 0.36,
                                    height: 4,
                                }}
                                key={i}
                                onClick={() => handleClick(i)}
                            />
                        ))}
                    </div>
                </div>
            );
        },
    };

    return (
        <div className={"my-[50px]"}>
            <Slider {...settings} ref={sliderRef}>
                {[0, 0].map((item, i) => (
                    <Component i={i} />
                ))}
            </Slider>
        </div>
    );
}

function Component(i) {
    if (i === 0) {
        return (
            <div className={"mx-auto flex flex-col justify-center items-center w-[358px] h-[294px] rounded-[20px] border-[1px] border-[#313131]/60 backdrop-blur-2xl text-center px-[30px]"}>
                <div className={"flex flex-row mx-auto mt-[50px]"}>
                    <img className={"my-auto ml-auto"} src={"/asset-new-pc/aboutus/vote.svg"} />
                    <div className={"text-[20px] font-bold my-auto ml-[10px] mr-auto"}>Multi-voting function</div>
                </div>
                <div className={"max-w-[350px] text-[14px] mt-[50px]"}>In order to cast voting rights on multiple governances simultaneously with existing governance tokens, each governance token must be owned, but the proposed multi-voting feature allows one token to vote for each and every governance.</div>
            </div>
        );
    }
    else {
        return (
            <div className={"mx-auto flex flex-col justify-center items-center w-[358px] h-[294px] rounded-[20px] border-[1px] border-[#313131]/60 backdrop-blur-2xl text-center px-[30px]"}>
                <div className={"flex flex-row mx-auto mt-[50px]"}>
                    <img className={"my-auto ml-auto"} src={"/asset-new-pc/aboutus/ballot.svg"} />
                    <div className={"text-[20px] font-bold my-auto ml-[10px] mr-auto"}>Ballot function</div>
                </div>
                <div className={"max-w-[350px] text-[14px] mt-[50px]"}>In order to fundamentally block the leakage of personal information through the tracking of contract distribution history recorded on the crypto-wallet address, stored assets and NFT, etc., voting records are left on the blockchain while the participant's cryptocurrency wallet address not be made public.</div>
            </div>
        );
    }
}

function NextArrow(props) {
    const { onClick } = props;
    return (
        <div className={"absolute inset-0 z-50 h-[50px] w-[50px] opacity-80"} onClick={onClick}>
            <img src={"/asset-new/NextArrow.png"} alt="" />
        </div>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={"absolute inset-0 z-50 h-[50px] w-[50px] -translate-y-[180px] opacity-80"} onClick={onClick}>
            <img src={"/asset-new/PrevArrow.png"} alt="" />
        </div>
    );
}

export default VoteCarousel;
