import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function WhoUseCarousel({ data }) {
    const [current, setCurrent] = useState(0);
    const sliderRef = useRef();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(current > data?.length - 2 ? 0 : current + 1);
            handleClick(current > data?.length - 2 ? 0 : current + 1);
        }, 3500);

        return () => {
            clearInterval(interval);
        };
    }, [current, data?.length]);

    const handleClick = (i) => {
        if (current === data?.length - 1) {
            sliderRef.current?.slickGoTo(current + 1);
            setCurrent(i);
        } else {
            sliderRef.current?.slickGoTo(i);
            setCurrent(i);
        }
    };

    const settings = {
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        slidesToShow: 1,
        speed: 500,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        dots: true,
        variableWidth: true,
        afterChange: (current) => setCurrent(current),
        appendDots: (dots) => {
            return (
                <div className="flex justify-center gap-3 px-4 pt-4">
                    <div className={"flex justify-center"}>
                        {data?.map((_, i) => (
                            <button
                                className="mr-[6px] -translate-y-[15px] rounded-3xl bg-gray2"
                                style={{
                                    width: i === current ? 50 : 4,
                                    opacity: i === current ? 1 : 0.36,
                                    height: 4,
                                }}
                                key={i}
                                onClick={() => handleClick(i)}
                            />
                        ))}
                    </div>
                </div>
            );
        },
    };

    return (
        <div className={""}>
            <Slider {...settings} ref={sliderRef}>
                {data?.map((item, i) => (
                    <div key={i} className={"relative my-[10px] w-full"}>
                        <div className={"mx-auto px-[5px]"}>
                            <img className={"h-full w-full object-cover"} src={item} />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

function NextArrow(props) {
    const { onClick } = props;
    return (
        <div className={"absolute top-1/2 bottom-1/2 right-0 z-50 opacity-80 pr-[30px]"} onClick={onClick}>
            <img src={"/assets/whouse/arrow-next.svg"} alt="" />
        </div>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={"absolute top-1/2 bottom-1/2 z-50 opacity-80 pl-[30px]"} onClick={onClick}>
            <img src={"/assets/whouse/arrow-prev.svg"} alt="" />
        </div>
    );
}

export default WhoUseCarousel;
