import axios from "axios";
import { useEffect, useState } from "react";

export default function Footer() {
    const [contents, setContents] = useState([]);

    useEffect(() => {
        axios
            .get(`${"https://api.eggverse.io/"}api/footer`)
            .then((response) => setContents(response?.data?.data.rows))
            .catch((e) => console.error(e));
    }, []);

    useEffect(() => {
        console.log(contents);
    }, [contents]);

    return (
        <div className={"bg-box text-[11px] lgmr:min-h-[240px] text-left"}>
            <div className={"flex flex-row w-full pt-[30px] max-w-[1410px] mx-auto px-[20px] lgmr:hidden"}>
                <div className={"flex flex-col w-full"}>
                    <img className={"h-[31px] mr-auto"} src={"/assets/footer/logo.svg"} alt="" />
                    <div className={"text-[12px] text-gray1 mt-[50px] mb-[30px] text-right"}>Copyright © 2022 Avitex. All rights reserved.</div>
                </div>
            </div>
        </div>
    );
}
