import React, {useEffect, useState} from "react";
import HexGifCarouselAboutus from "./carousel/carousel-hexgif";

export default function SectionVideos() {
    const text = [
        {
            title: "Standardless",
            content: "Beyond the NFT general-purpose standard, the nth transaction of all NFTs through super-universal NFT transactions regardless of the standard itself"
        },
        {
            title: "Multi-smartcontract",
            content: "Infinite expansion of NFT mainet on one platform"
        },
        {
            title: "Meta-Bridge Economy",
            content: "S2EXT2E, Decentralized economic system considering mutual contribution"
        },
        {
            title: "NFT Trading Mainnet matching Technology",
            content: "Support all NFT content transactions around the world through NFT block Crawling and Register-less transaction function"
        },
        {
            title: "Interlocking with Real",
            content: "O2O NFT platform connecting virtual and real through IRL(in real life) smart contract"
        },
    ];

    return (
        <div className={"relative max-w-[1420px] pt-[200px] pb-[100px] lgr:pt-[150px] lgr:pb-[30px] flex flex-col z-10 mx-auto small:flex-col small:max-h-[1200px] px-[10px]"}>
            <div className={"text-center text-[30px] 2xl:text-[50px] lgr:text-[20px] font-bold leading-tight tracking-wide"}>Technical Points of EGGVERSE</div>
            <div className={"text-[18px] 2xl:text-[30px] lgr:text-[14px] lgr:mt-[10px] text-center text-gray3"}>Multi-smartcontract standardless NFT open market</div>
            <div className={"2xl:hidden 2xl:pointer-events-none"}>
                <HexGifCarouselAboutus data={text} />
            </div>
            <div className={"grid grid-cols-3 mt-[50px] gap-y-[20px] gap-x-[20px] 2xlr:hidden 2xlr:pointer-events-none"}>
                {[1, 2, 3, 4, 5].map((num, i) => (
                    <div className={`${num === 1 ? "col-span-2" : "col-span-1"}`}>
                        <Video num={num} text={text} idx={i} />
                    </div>
                ))}
            </div>
        </div>
    );
}

function Video(props) {
    const [hover, setHover] = useState(false);
    const [play, setPlay] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        console.log(loaded);
    }, [loaded]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (hover) setPlay(true);
        }, 100);

        return () => {
            clearInterval(interval);
            setPlay(false);
        };
    }, [hover]);

    return (
        <div className={`${props.num === 1 ? "min-w-[918px]" : "min-w-[450px] max-w-[450px]"} h-[450px]`} onMouseEnter={() => setHover(true)} onMouseLeave={() => {setPlay(false); setHover(false)}}>
            {hover ? (
                <div className={"relative"}>
                    <div className={"absolute"}>
                        <img className={"w-full h-full object-cover"} src={`/assets/videos/${props.num}img.webp`} />
                    </div>
                    <div className={"relative z-30 min-h-[450px]"}>
                        <video className={`mx-auto w-full object-fit duration-500 ${play ? "scale-[1.2]" : "scale-[1]"}`} autoPlay muted playsInline loop>
                            <source src={`/assets/videos/${props.num}.mp4`} type={"video/mp4"} />
                        </video>
                        <div className={`absolute py-[50px] bottom-0 duration-500 ${play ? "opacity-100" : "opacity-0"}`}>
                            <div className={"w-full h-full"}>
                                <div className={`text-[30px] ${props.num === 1 && "text-gray1"}`}>{props.text[props.idx]?.content}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={"relative z-20"}>
                    <img className={"w-full h-full object-cover"} src={`/assets/videos/${props.num}img.webp`} />
                    <div className={`absolute px-[40px] py-[50px] bottom-0`}>
                        <div className={"w-full h-full"}>
                            <div className={"font-semibold text-[40px]"}>{props.text[props.idx]?.title}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}