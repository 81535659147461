export default function Partners() {

    const partners = () => {
        const out = [];
        for (let i = 1; i <= 15; i++) {
            if (false) {
                continue;
            }
            else {
                out.push(
                    <div className={"big:w-[270px] big:h-[185px] small:max-w-[270px] rounded-2xl mx-auto mb-[60px] small:pl-[10px] small:pr-[10px]"}>
                        <img className={"w-full h-full object-contain rounded-2xl border-[1px] border-[#c6dbef]"} src={`./assets/partners/${i}.png`} />
                    </div>
                );
            }
        }
        return out;
    }

    return (
        <div className={"relative h-full w-screen min-h-[1100px] pt-[100px] lgr:pt-[50px] text-black bg-white lgr:pb-[50px]"}>
            <div className={"w-full h-full big:min-w-[1300px]"}>
                <h1 className={"text-center text-[50px] lgr:text-[30px] font-bold leading-tight tracking-wide"}>Partners</h1>
                <p className={"text-center text-[20px] lgr:text-[16px] mt-[10px] mb-[60px] text-[#888888] leading-tight tracking-wide"}>Who make us even greater.</p>
                <div className={"grid big:grid-cols-4 small:grid-cols-2 max-w-[1340px] mx-auto"}>
                    {partners()}
                </div>
            </div>
        </div>
    );
}