import PieGraph from "./graphs/PieGraph";
import useWindowDimensions from "./utils/WindowDimensions";
import PieGraphSmall from "./graphs/PieGraphSmall";

export default function Distribution() {
    const { height, width } = useWindowDimensions();

    const data = [
        {
            "id": "Reward",
            "label": "Reward",
            "value": 0.10,
        },
        {
            "id": "Reserve",
            "label": "Reserve",
            "value": 0.25,
        },
        {
            "id": "Ecosystem",
            "label": "Ecosystem",
            "value": 0.30,
        },
        {
            "id": "Marketing",
            "label": "Marketing",
            "value": 0.10,
        },
        {
            "id": "Partner",
            "label": "Partner",
            "value": 0.10,
        },
        {
            "id": "Liquidity",
            "label": "Liquidity",
            "value": 0.05,
        },
        {
            "id": "Members",
            "label": "Members",
            "value": 0.10,
        },
    ];

    if (width >= 1300) {
        return (
            <div className={"relative mx-auto flex flex-row px-[10px] py-[100px] mb-[60px]"}>
                <div className={"w-full h-full flex flex-row max-w-[1400px] ml-auto mr-auto"}>
                    <div className={"w-full h-full flex flex-col"}>
                        <div className={"mb-auto"}>
                            <h1 className={"text-left text-[50px] font-bold leading-tight mt-auto mb-[50px]"}>EGGT Token Allocation</h1>
                            <p className={"text-left text-[20px]"}>The total amount of ERC20 EGGT tokens issued is 1,000,000,000, and they are distributed as follows.</p>
                        </div>
                    </div>
                    <div className={"flex w-full max-w-[800px] min-w-[800px]"}>
                        <div className={"w-full h-[500px] mt-auto mb-auto"}>
                            <PieGraph data={data} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className={"relative h-screen w-screen big:max-h-[650px] small:h-auto ml-auto mr-auto flex flex-row py-[60px]"}>
                <div className={"w-full h-full flex flex-col max-w-[1400px] ml-auto mr-auto"}>
                    <div className={"w-full h-full flex flex-col"}>
                        <div className={"big:mt-auto big:ml-[100px]"}>
                            <h1 className={"text-left text-[30px] font-bold leading-tight mt-auto big:mb-[50px] small:mb-[20px] small:text-center"}>EGGT Token Allocation</h1>
                            <p className={"text-left text-[16px] small:text-center small:pl-[30px] small:pr-[30px] mb-[10px]"}>The total amount of ERC20 EGGT tokens issued is 1,000,000,000, and they are distributed as follows.</p>
                        </div>
                    </div>
                    <div className={"flex w-full max-w-[700px] mx-auto"}>
                        <div className={"w-full big:h-[500px] small:h-[280px] mb-[30px]"}>
                            <PieGraphSmall data={data} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}