import CardRoadmap from "../components/card/card-roadmap";
import RoadmapCarousel from "./carousel/carousel-roadmap";

export default function Roadmap({ data }) {
    return (
        <div className={"relative flex flex-row mb-[120px] py-[100px] text-white"}>
            <div className={"flex flex-col ml-auto mr-auto"}>
                <h1 className={"text-center text-[50px] lgr:text-[30px] font-bold"}>Roadmap</h1>
                <p className={"text-center text-[20px] lgr:text-[16px] mt-[10px] mb-[60px] text-[#888888] mb-[60px]"}>The path we have walked, the direction we aim for.</p>
                <div className={"flex flex-row w-full 2xlr:hidden"}>
                    {data.map((item, i) => (
                        <CardRoadmap data={item} idx={i} />
                    ))}
                </div>
            </div>
        </div>
    );
}