import React, {useEffect, useState} from "react";

export default function SectionEcosystem() {
    return (
        <div className={"relative max-w-[1420px] py-[200px] flex flex-col z-10 mx-auto small:flex-col small:max-h-[1200px] px-[10px]"}>
            <div className={"text-center 2xl:text-[50px] text-[30px] lgr:text-[20px] font-bold leading-tight tracking-wide"}>Community_based Ecosystem</div>
            <div className={"2xl:text-[30px] text-[18px] lgr:text-[14px] text-center max-w-[1000px] mx-auto mt-[30px] lgr:mt-[10px]"}>EGGVERSE community is consist of 5  roles which can be become by holding EGGVERSE role NFT. By the powers of each role, checks and balances are established in the community.</div>
            <div className={"mt-[100px] lgr:mt-[30px]"}>
                <img className={"w-full h-full object-cover"} src={"/assets/ecosystem.svg"} />
            </div>
        </div>
    );
}