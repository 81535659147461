import { Route, Routes} from "react-router-dom";
import Document from "./Document";
import Home from "./Home";
import AboutUs from "./aboutus/index";
import AdminLogin from "./AdminLogin";

function Router(props) {
    return (
        <div>
            <Routes>
                <Route path="/" element={<AboutUs />}/>
                {/* <Route path="/document" element={<Document login={props.login} setLogin={props.setLogin}/>} /> */}
                {/* <Route path="/document/admin" element={<AdminLogin setLogin={props.setLogin}/>}/> */}
            </Routes>
        </div>
    );
}

export default Router;