import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardRoadmap from "../card/card-roadmap";

function RoadmapCarousel({ data }) {
    const [current, setCurrent] = useState(0);
    const sliderRef = useRef();

    useEffect(() => {
        console.log(data);
    }, [data]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(current > data?.length - 2 ? 0 : current + 1);
            handleClick(current > data?.length - 2 ? 0 : current + 1);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [current, data?.length]);

    const handleClick = (i) => {
        if (current === data?.length - 1) {
            sliderRef.current?.slickGoTo(current + 1);
            setCurrent(i);
        } else {
            sliderRef.current?.slickGoTo(i);
            setCurrent(i);
        }
    };

    const settings = {
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        dots: true,
        variableWidth: true,
        afterChange: (current) => setCurrent(current),
        appendDots: (dots) => {
            return (
                <div className="flex justify-center gap-3 px-4 pt-4">
                    <div className={"flex justify-center"}>
                        {data?.map((_, i) => (
                            <button
                                className="mr-[6px] -translate-y-[15px] rounded-3xl bg-gray2"
                                style={{
                                    width: i === current ? 50 : 4,
                                    opacity: i === current ? 1 : 0.36,
                                    height: 4,
                                }}
                                key={i}
                                onClick={() => handleClick(i)}
                            />
                        ))}
                    </div>
                </div>
            );
        },
    };

    return (
        <div className={""}>
            <Slider {...settings} ref={sliderRef}>
                {data?.map((item, i) => (
                    <div key={i} className={"my-[10px] w-full px-[10px]"}>
                        <CardRoadmap data={item} idx={i} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

function NextArrow(props) {
    const { onClick } = props;
    return (
        <div className={"absolute inset-0 z-50 h-[50px] w-[50px] opacity-80"} onClick={onClick}>
            <img src={"/asset-new/NextArrow.png"} alt="" />
        </div>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={"absolute inset-0 z-50 h-[50px] w-[50px] -translate-y-[180px] opacity-80"} onClick={onClick}>
            <img src={"/asset-new/PrevArrow.png"} alt="" />
        </div>
    );
}

export default RoadmapCarousel;
